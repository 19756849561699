function animateStroke(selector)
{
    outline = $(selector)
    outline.each(function(){
        splitChar($(this))
    })
}

function splitChar(node) {
    if(node.children().length)
    {
        return node.children().each(function(){ splitChar($(this)) })
    }
    var htmlContent = node.html(),
        words = htmlContent.split(' '),
        newContent = ''

    words.forEach(word => {
        var letters = word.split(''),
            newWord = ''

        letters.forEach(l => {
            newWord += '<span>' + l + '</span>'
        })
        newContent += '<div>' + newWord + '</div>'
    })  
    node.html(newContent)
}


window.addEventListener('scroll', function(e){
    $('[outline]').each(function(){
        var outline = $(this),
            outlineHeight = outline.innerHeight(),
            outlinedElements = outline.find('span'),
            outlinedElementsCount = outlinedElements.length,    
            position = outline.offset().top,
            scroll = $(window).scrollTop(),
            offset = 0.45, //frazione di schermo da raggiungere ( 0.35=> 35% della finestra dal basso ) per innescare l'animazione
            threshold = scroll - position + window.innerHeight*(1-offset)

        if( threshold > 0)
        {
            var percent = threshold*100/outlineHeight,
                limit = percent*outlinedElementsCount/100,
                filled = 0
            
            outlinedElements.each(function(){
                if (percent <= 1)
                {
                    $(this).removeClass('fill')
                    filled = 0
                    return
                }
                if(filled <= limit)
                {
                    $(this).addClass('fill')
                    filled++
                }
                else
                {
                    $(this).removeClass('fill')
                }
                return
            })
        }
    })    
})
